import React, { useEffect, useCallback, useState } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dropdown from "../../components/controls/Dropdown";
import Buttons from "../../components/controls/Button";
import ToggleSwtich from '../../components/controls/ToggleSwitch'
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import AutoComplete from "../../components/controls/AutoComplete";
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import {
  getBranchSalesrep,
  getPartsfromSets,
  getPartsfromUsage,
  getSubInventory,
  getShipToValues
} from "../../graphql/queries";
import {
  updatePartsUsed,
  updateCapPrice,
  transfromCapPricesToPayload,
  setContractPrices,
} from "../../graphql/mutations";
import SalesRep from "../../components/new-case-form/SalesRep";
import InputTextField from "../../components/controls/Input";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { SHIPTO, REPLENISH, USER_TYPE, ASYNC_STORAGE } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, TextField, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import getCaseById from "../../utils/calendar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import MultiSelectCheckBoxDropdown from "../../components/controls/MultiSelectCheckBoxDropdown";
import {
  formValueChanged,
  resetFormChangeIndicator,
} from "../../redux/modules/formChangeLog";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
    background: "#FAFAFA",
  },
  formControl: {
    margin: "25px 15px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  toAddress: {
    color: "#000",
    fontWeight: 600,
    marginLeft: "5px",
    fontSize: "16px",
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
  customThead: {

  }
}));




const StyledCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#FFB500",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Parts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const GetCaseById_Set = (response) =>
    dispatch(userCase.setCaseDetail(response));
  const valueChanged = (name) => dispatch(formValueChanged(name));
  const resetChangeLogIndicator = () => dispatch(resetFormChangeIndicator());
  const { isFormDirty } = useSelector((state) => state.formChangeLog);
  const surgicalCaseDetails = useSelector(
    (state) =>
      state.cases && state.cases.currentState && state.cases.currentState
  );
  const [loader, setLoader] = React.useState(false);
  const [saveLoader, setSaveLoader] = React.useState(false);
  const [branchesValue, setbranchesValue] = React.useState(
    surgicalCaseDetails.branchSfId
  );
  const [searchfromSetOptions, setSearchfromSetOptions] = React.useState([]);
  const [partsFromSets, setPartsFromSets] = useState([]);
  const [selectedSetParts, setSelectedSetParts] = useState([]);
  const [selectedSet, setSelectedSet] = useState(null);
  const [addPartsOptions, setAddPartsOptions] = React.useState([]);
  const [qtyOptions, setQtyOptions] = React.useState([]);
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const [selectedPartFromSet, setSelectedPartFromSet] = React.useState([]);
  const [selectedPart, setSelectedPart] = React.useState(null);
  const [usageParts, setUsageParts] = React.useState([]);
  const [salesRepsOptions, setsalesRepsOptions] = React.useState({});
  const [hospitalPrintedName, setHospitalPrintedName] = useState(
    props.caseDetails.signatureHospitalRepName
  );
  const [salesRep, setSalesRep] = useState({});
  const [disableControl, setDisableControl] = useState(false);
  const [disable, setDisable] = useState(false);
  const [capBtn, setCapBtn] = React.useState(props.caseDetails.capPrice);
  const [WebOpsId, setWebOpsId] = React.useState('');
  const [customReplenish, setCustomReplenish] = React.useState([]);
  const [customReplenish2, setCustomReplenish2] = React.useState([]);
  const [customShipTo, setCustomShipTo] = React.useState([]);
  const [customDropdownValues, setCustomDropdownValues] = React.useState([{ id: true, label: "Y" }, { id: false, label: "N" }]);
  const [printData, setPrintData] = React.useState([true]);
  const [shipMethodValues, setShipMethodValues] = React.useState([]);
  const [shipPriorityValues, setShipPriorityValues] = React.useState([]);
  const [checkPartExist, setCheckPartExist] = React.useState([]);

  useEffect(() => {
    if (props.caseDetails.print_data__c != null) {
      let dynamicPrintDataValue = props.caseDetails.print_data__c;
      let dynamicPrintDataValue2 = dynamicPrintDataValue;
      setPrintData(dynamicPrintDataValue2);
    }
    customShipToValues();
    getSubInventoryData();
    localStorage.setItem("getCaseDetails", JSON.stringify(props.caseDetails));
    setDisableControl(props.caseDetails.status === "Completed");
    setDisable(props.caseDetails.revision || props.caseDetails.capPrice)
    return () => {
      resetChangeLogIndicator();
    };
  }, [props.caseDetails]);

  useEffect(() => {
    setLoader(true);
    const qtyArray = generateQtyOptions();
    setQtyOptions(qtyArray);

    //get all avaialeble parts from sets;
    if (props.caseDetails) {
      getPartsfromSets(props.caseDetails.sfId, props.caseDetails.externalId, "")
        .then((res) => {
          return res.get_parts_from_consignment_sets.map((item) => {
            item.title = item.part_name;
            item.id = item.product_sfid;
            return item;
          });
        })
        .then((res) => {
          setPartsFromSets(res);
          //set up case sets
          const sets =
            surgicalCaseDetails &&
            surgicalCaseDetails.sets &&
            surgicalCaseDetails.sets.map((item) => {
              item.title = item.set_name;
              item.id = item.set_id;
              return item;
            });
          setSearchfromSetOptions(sets);
        });
    }

    getBranchSalesrep(branchesValue)
      .then((res) => {
        const sortedUserByBranch = res.get_user_by_branch.sort(compare);
        return sortedUserByBranch.map((item) => {
          item.title = item.userName;
          item.id = item.userID;
          return item;
        });
      })
      .then((res) => {
        setsalesRepsOptions(res);
        if (!props.caseDetails.signatureSalesRepName) {
          // default to case sales rep
          const useObj = res.find(
            (users) => users.userID === props.caseDetails.userRep.toString()
          );
          setSalesRep(useObj);
        } else {
          const rep = res.find(
            (users) =>
              users.userName ===
              props.caseDetails.signatureSalesRepName.toString()
          );
          setSalesRep(rep);
        }
      });

    const savedParts = surgicalCaseDetails && surgicalCaseDetails.usages;
    setUsageParts(savedParts);
    setWebOpsId(surgicalCaseDetails.webops_id__c);
  }, [branchesValue]);

  async function customShipToValues() {
    await getShipToValues("CustomObject", "casesusage__c", "false").then((res) => {
      setShipPriorityValues(res.get_ship_to_values.ship_priority_values);
      setShipMethodValues(res.get_ship_to_values.ship_method_values);
    }).catch(error => {
      setLoader(false);
      console.log(error);
    })
  }

  async function getSubInventoryData() {
    setLoader(true);
    await getSubInventory(props.caseDetails.hospitalid__c, props.caseDetails.branchSfId).then((res) => {
      let inventoryArr = [];
      //  let inventoryArr = [{ id: "Home", label: "Home", "labeltoShow": "yess" }];
      let inventoryArrShipTo = [{ id: "Home", label: "Home", id2: "Home" }];
      let hospital = [];
      let branch = [];
      let subBranch = [];
      let replenishToHospital = [];
      let replenishToBranch = [];
      let replenishToSubBranch = [];
      let res2;
      for (let i = 0; i < res.get_Sub_Inventory.length; i++) {
        if (res.get_Sub_Inventory[i].subinvdescofHospital != null) {
          hospital.push({ id: res.get_Sub_Inventory[i].subinvnameofHospital, label: res.get_Sub_Inventory[i].subinvdescofHospital });
          replenishToHospital.push({ id: res.get_Sub_Inventory[i].oraclesitenumber, label: res.get_Sub_Inventory[i].subinvdescofHospital, id2: res.get_Sub_Inventory[i].subinvnameofHospital });
        }
        if (res.get_Sub_Inventory[i].subinvdescofbranch != null) {
          branch.push({ id: res.get_Sub_Inventory[i].subinvnameofbranch, label: res.get_Sub_Inventory[i].subinvdescofbranch });
          replenishToBranch.push({ id: res.get_Sub_Inventory[i].AddressERPCode, label: res.get_Sub_Inventory[i].subinvdescofbranch, id2: res.get_Sub_Inventory[i].subinvnameofbranch });
        }
        if (res.get_Sub_Inventory[i].subinvdescofSubbranch != null) {
          subBranch.push({ id: res.get_Sub_Inventory[i].subinvnameofSubbranch, label: res.get_Sub_Inventory[i].subinvdescofSubbranch });
          replenishToSubBranch.push({ id: res.get_Sub_Inventory[i].ERPCodeofSubBranch, label: res.get_Sub_Inventory[i].subinvdescofSubbranch, id2: res.get_Sub_Inventory[i].subinvnameofSubbranch });
        }
      }
      let concatArray = hospital.concat(branch);
      concatArray = concatArray.concat(subBranch);
      let concatArray2 = replenishToHospital.concat(replenishToBranch);
      concatArray2 = concatArray2.concat(replenishToSubBranch);
      inventoryArr = inventoryArr.concat(concatArray);
      let e = inventoryArr;
      let shipToCustom = [];
      let replenishToCustom = [];
      replenishToCustom = replenishToCustom.concat(concatArray2);
      shipToCustom = shipToCustom.concat(concatArray2);
      replenishToCustom.push({ id: "Home", label: "Home" });
      replenishToCustom.push({ id: "HospitalOwned", label: "Hospital Owned" });
      replenishToCustom.push({ id: "NationalLoaner", label: "National Loaner" });
      replenishToCustom.push({ id: "SalesRepInventory", label: "Sales Rep Inventory" });
      replenishToCustom.push({ id: "Other", label: "Other" });
      inventoryArr.push({ id: "Home", label: "Home" });
      inventoryArr.push({ id: "HospitalOwned", label: "Hospital Owned" });
      inventoryArr.push({ id: "NationalLoaner", label: "National Loaner" });
      inventoryArr.push({ id: "SalesRepInventory", label: "Sales Rep Inventory" });
      inventoryArr.push({ id: "Other", label: "Other" });
      const t = inventoryArr;
      setCustomReplenish(inventoryArr);
      setCustomReplenish2(replenishToCustom);
      shipToCustom.push({ id: "HFPU", label: "HFPU", id2: "HFPU" });
      shipToCustom.push({ id: "Home", label: "Home", id2: "Home" });
      shipToCustom.push({ id: "HospitalOwned", label: "Hospital Owned", id2: "HospitalOwned" });
      shipToCustom.push({ id: "NationalLoaner", label: "National Loaner", id2: "NationalLoaner" });
      shipToCustom.push({ id: "SalesRepInventory", label: "Sales Rep Inventory", id2: "SalesRepInventory" });
      shipToCustom.push({ id: "Other", label: "Other", id2: "Other" });
      setCustomShipTo(shipToCustom);
      setLoader(false);

    }).catch(error => {
      setLoader(false);
      console.log(error);
    })
  }

  const onChangeFromSetValue = (selectedSet) => {
    let availableParts = [];
    if (selectedSet) {
      availableParts = partsFromSets.filter(
        (part) => part.set_id === selectedSet.set_id
      );
    }
    setSelectedSet(selectedSet ? selectedSet : null);
    setSelectedSetParts(availableParts);
    setSelectedPartFromSet([]);
  };

  const onSelectSetParts = (event, selectedPart) => {
    setSelectedPartFromSet(selectedPart);

  };

  const onChangePartsInputValue = async (searhText) => {
    await getPartsFromUsage(searhText);
  };

  const onSelectParts = (selectedPart) => {
    setSelectedPart(selectedPart);
    if (!selectedPart) {
      setAddPartsOptions([]);
    }
  };
  const handleCAP = (e) => {
    setCapBtn(e.target.checked)
    if (e.target.checked === true) {
      setDisable(true)
    } else if (e.target.checked === false) {
      setDisable(false)
    }
  }

  const getPartsFromUsage = async (searhText) => {
    if (props.caseDetails && searhText.length >= 2) {
      getPartsfromUsage(searhText)
        .then((res) => {
          return res.get_parts_for_usage.map((item) => {
            item.title = item.part_name;
            item.id = item.product_sfid;
            return item;
          });
        })
        .then((res) => {
          setAddPartsOptions(res);
        });
    }
  };

  const getPartsFromCaseSets = async (searhText) => {
    if (props.caseDetails) {
      getPartsfromSets(
        props.caseDetails.sfId,
        props.caseDetails.externalId,
        searhText
      ).then((res) => {
        return res.get_parts_from_consignment_sets.map((item) => {
          item.title = item.part_name;
          item.id = item.product_sfid;
          return item;
        });
      });
    }
  };

  const onChangeWebOpsId = (event) => {
    setWebOpsId(event.target.value);
  }

  const onChangeHospitalName = (event) => {
    if (!isFormDirty) {
      valueChanged(event.target.name);
    }
    setHospitalPrintedName(event.target.value);
  };
  const onChangeSalesRep = (rep) => {
    if (!isFormDirty) {
      valueChanged("SalesRep");
    }
    setSalesRep(rep);
  };

  const onChangeCheckboxControl = (event, key, row) => {
    if (!isFormDirty) {
      valueChanged("LotControlledYN");
    }
    let arrary =
      usageParts &&
      usageParts.map((item) => {
        if (item === row) {
          item[key] = event.target.checked;
          if (key === "replenish__c") {
            item.replenish__c = !event.target.checked;
            item.ship_to__c = event.target.checked ? "" : item.ship_to__c;
            item.shipto_location__c = event.target.checked
              ? ""
              : item.shipto_location__c;
          } else if (key === "lot_controlled__c" && event.target.checked) {
            item.quantity__c = 1;
          } else if (key === "revisions__c") {
            item.revisions__c = event.target.checked
          }
        }
        return item;
      });
    setUsageParts(arrary);
  };
  const generateQtyOptions = () => {
    return Array.from(new Array(99), (val, index) => ({
      value: index + 1,
      label: index + 1,
    }));
  };
  const compare = (a, b) => {
    const c1 = a.userName;
    const c2 = b.userName;
    var comp = 0;
    if (c1 > c2) comp = 1;
    else if (c1 < c2) comp = -1;
    return comp;
  };

  const onChangeDropdown = (event, key, row) => {
    if (!isFormDirty) {
      valueChanged(event.target.name);
    }
    let arrary =
      usageParts &&
      usageParts.map((item) => {
        if (item === row) {
          item[key] = event.target.value;
          if (key == "inventory__c" && item.replenish__c == true) {
            let e = event.target.value;
            let customId;
            for (let i = 0; i < customShipTo.length; i++) {
              if (customShipTo[i].id2 == e) {
                customId = customShipTo[i].id;
                break;
              }
            }

            item["ship_to__c"] = customId;
            item["shipto_location__c"] = customId;
          }
        }
        return item
      })
    setUsageParts(arrary);

  };

  const onChangeDropdown2 = (event) => {
    setPrintData(event.target.value);
  }

  const onChangeLotNbr = (event, row) => {
    if (!isFormDirty) {
      valueChanged("LotNbr");
    }
    const _lotNbr = event.target.value.toUpperCase();
    let arrary =
      usageParts &&
      usageParts.map((item) => {
        if (
          item.product_sfid === row.product_sfid &&
          (!row.lot_controlled__c || item.lot_number__c === row.lot_number__c) && item === row
        ) {
          item.lot_number__c = _lotNbr;
        }
        return item;
      });
    setUsageParts(arrary);
  };

  const handleAddUsage = () => {
    let parts = [];
    if (selectedPartFromSet) {
      parts.push(...selectedPartFromSet);
    }
    if (selectedPart) {
      parts.push(selectedPart);
    }

    let partsToAdd = checkPartExist;
    partsToAdd.push(parts[0].part_name);
    setCheckPartExist(partsToAdd);

    parts = parts.map((item) => {
      let obj = {};
      obj.product_sfid = item.product_sfid;
      obj.part_name = item.part_name;
      obj.wasted = item.wasted || null;
      obj.dns__c = item.dns__c
      obj.revisions__c = item.revisions__c || null
      obj.replenish__c = item.replenish__c || true;
      obj.ship_to__c = item.ship_to__c || "";
      obj.shipto_location__c = item.shipto_location__c || "";
      obj.ship_method__c = "12"
      obj.ship_priority__c = "REGULAR"
      obj.lot_controlled__c = item.is_lot_controlled || false;
      obj.lot_number__c = item.lot_number__c || "";
      obj.quantity__c = item.quantity__c ? (item.quantity__c === "0" ? 1 : item.quantity__c) : 1;
      obj.capStatus = capBtn;
      obj.catalog_number = item.catalog_number;
      return obj;
    });

    const partsArr = [...usageParts, ...parts];
    if (isDuplicateExist(partsArr, false)) {
      props.displaySnackBarMessage("error", "Selected part(s) already exists");
      return;
    }

    setUsageParts(partsArr);
    setSelectedSet(null);
    setSelectedPartFromSet([]);
    setSelectedPart(null);
  };

  const isLotNbrEmptyforLotYes = (partsData) => {
    const lotControlledParts =
      partsData &&
      partsData.filter(
        (item) =>
          item.lot_controlled__c &&
          (item.lot_number__c === "" || item.lot_number__c === null)
      );
    return lotControlledParts && lotControlledParts.length > 0;
  };

  const isDuplicateExist = (array, checkLots = false) => {
    const idArr = array
      .map((item) => {
        if (!checkLots) {
          if (item.lot_controlled__c) {
            return null;
          }
          return item.product_sfid;
        }
      })
      .filter((item) => item != null);
    return idArr.some((item, idx) => {
      return idArr.indexOf(item) !== idx;
    });
  };

  const getParts = async (usageParts) => {
    return usageParts.forEach((object) => {
      delete object["capBtn"];
    });
  };

  const handleSaveUsage = async () => {
    if (isDuplicateExist(usageParts, true)) {
      props.displaySnackBarMessage(
        "error", "Duplicate part(s) added. Please remove duplicate part(s) and save"
      );
      return
    }
    // Check for lot # if Lot Y/N Checked
    if (isLotNbrEmptyforLotYes(usageParts)) {
      props.displaySnackBarMessage(
        "error", "Please fill out or select the following required fields: Lot Number"
      );
      return
    }
    setSaveLoader(true)
    let checkAroReferenceLengthGreaterThanEight = [];
    let checkAroReferenceIsNumeric = [];
    try {
      const parts = usageParts && usageParts.map(item => {
        if (item && item.aro_reference_number__c && item.aro_reference_number__c.length > 15) {
          checkAroReferenceLengthGreaterThanEight.push(item.aro_reference_number__c);
          props.displaySnackBarMessage("error", ` For part number ${item.part_name}  , ARO Reference Number length should not be more than 15`);
          return;

        }
        if (item.aro_reference_number__c !== undefined) {
          var isNumber = /^[a-zA-Z0-9]+$/.test(item.aro_reference_number__c);
          if (isNumber === false) {
            checkAroReferenceIsNumeric.push(item.aro_reference_number__c);
            props.displaySnackBarMessage("error", ` For part number ${item.part_name}  , ARO Reference Number should only contains alpha-numeric values`);
            return;
          }
        }
        customShipTo.map(data => {
          if (data.label === item.inventory__c) {
            item.inventory__c = data.id2;
          } if (data.label === item.shipto_location__c) {
            item.shipto_location__c = data.id;
          } if (data.label === item.ship_to__c) {
            item.ship_to__c = data.id;
          }
        })

        console.log("--item--", item)
        return {
          sfid: item.sfid,
          external_id__c: item.external_id__c,
          surgical_cases__c: props.caseDetails.sfId,
          surgical_cases__r__external_id__c: props.caseDetails.externalId,
          product__c: item.product_sfid,
          quantity__c: item.quantity__c === null && item.lot_controlled__c ? 1 : item.quantity__c,
          lot_number__c: item.lot_number__c || "",
          wasteds__c: item.wasted,
          side__c: item.side__c || "",
          lot_controlled__c: item.lot_controlled__c || false,
          ship_to__c: item.ship_to__c || '',
          shipto_location__c: item.shipto_location__c || '',
          replenish__c: item.replenish__c || false,
          procedure__c: item.procedure__c || props.caseDetails.procedureid__c || "",
          inventory_product_system__c: item.inventory_product_system__c || '',
          serial_number__c: item.serial_number__c || '',
          dns__c: item.dns__c,
          revisions__c: props.caseDetails.revision === true ? true : item.revisions__c,
          inventory__c: item.inventory__c || '',
          aro_reference_number__c: (item.aro_reference_number__c == null || item.aro_reference_number__c == "!" ? WebOpsId : item.aro_reference_number__c),
          ship_priority__c: item.ship_priority__c,
          ship_method__c: item.ship_method__c

        }
      })
      const modelNArr = [];
      usageParts && usageParts.map(part => {
        let usages = {};
        usages.catalog_number = part.catalog_number;
        usages.quantity__c = part.quantity__c === null && part.lot_controlled__c ? 1 : part.quantity__c;
        usages.wasted = part.wasted;
        usages.capPrice = capBtn;
        modelNArr.push(usages);
      })

      const newUsages = { externalId: props.caseDetails.externalId, hospitalid__c: props.caseDetails.hospitalid__c, revision: props.caseDetails.revision, surgeryDate: props.caseDetails.surgeryDate, caseId: props.caseDetails.caseId, usages: modelNArr }

      document.body.style.pointerEvents = "none";
      valueChanged('Parts Are Adding...');

      /** MP:To set cap value **/
      const capPricePayload = transfromCapPricesToPayload(
        props.caseDetails,
        [],
        null,
        null,
        capBtn,
        null,
      );
      await updateCapPrice(capPricePayload);

      let checkPrintData = true;
      await updatePartsUsed(
        props.caseDetails.sfId,
        props.caseDetails.externalId,
        parts,
        hospitalPrintedName,
        typeof salesRep === "object" ? salesRep.title : salesRep,
        props.caseDetails.status,
        checkPrintData
      );

      await getCases();
      setCheckPartExist([]);
      setTimeout(() => {
        setSaveLoader(false);
        props.displaySnackBarMessage(
          "success",
          "Selected parts saved successfully."
        );
        document.body.style.pointerEvents = "unset";
      }, 5000);

      resetChangeLogIndicator();
    } catch (error) {
      document.body.style.pointerEvents = "unset";
      setLoader(false);
      if (checkAroReferenceLengthGreaterThanEight.length > 0 || checkAroReferenceIsNumeric.length > 0) {
        return;
      }
      props.displaySnackBarMessage(
        "error",
        "Something went wrong, please try again."
      );
    }
  };
  const getCases = async () => {
    const res = await getCaseById(
      props.caseDetails.externalId,
      props.caseDetails.sfId
    ).then((res) => {
      GetCaseById_Set(res.data);
    });
  };
  const deleteParts = async (event, row) => {
    let arrary = usageParts && usageParts.filter((item) => item !== row);
    setUsageParts(arrary);
    if (!isFormDirty) {
      valueChanged("DelParts");
    }
  };

  function checkPartNumberEditable(partName) {
    let check = "true";
    if (checkPartExist.includes(partName) == true) {
      return false;
    }
    return check;
  }

  return loader || saveLoader ? (
    <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
  ) : (
    <div>
      <Grid container item xs={12} spacing={0} className="backgroundgreycolor">
        <Grid item xs={12} sm={6}>
          <AutoComplete
            fnc={onSelectParts}
            onInputChange={onChangePartsInputValue}
            label={"Search"}
            value={selectedPart}
            options={addPartsOptions}
            Isdisabled={disableControl}
            isAstrikReq={true} />
          <div style={{ marginLeft: '5px', fontSize: '12px' }}>Part No - Name(Please enter part # / detailed description)</div>
        </Grid>
        <Grid container spacing={0} xs={12} sm={6}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              fnc={onChangeFromSetValue}
              label={"From Sets"}
              value={selectedSet}
              options={searchfromSetOptions}
              Isdisabled={disableControl}
              isAstrikReq={true} />
          </Grid>
          {selectedSet && selectedSetParts.length > 0 &&
            <Grid item xs={12} sm={12}>
              <MultiSelectCheckBoxDropdown
                isAstrikReq={true}
                label={'Available Parts'}
                limitTags={2}
                chipWidth={'220px'}
                disabled={disableControl}
                options={selectedSetParts}
                fnc={onSelectSetParts}
                value={selectedPartFromSet}
              />
            </Grid>
          }
          {selectedSet && selectedSetParts.length === 0 &&
            <div style={{ marginLeft: '10px', fontSize: '12px', color: 'red' }}>No Parts Available</div>
          }
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid item sm={11} xs={12}>
            <CardContent>

            </CardContent>
          </Grid>
        </Grid>
        <div className="lFloat toggleBtn">
          <ToggleSwtich
            fnc={handleCAP}
            checked={capBtn}
            label="CAP"
            labelPlacement="start"
            disabled={props.caseDetails.status === "Completed"} />
        </div>
        <div class="rFloat btnSave">
          <Buttons
            fnc={handleAddUsage}
            Icon="Add"
            toSave={true}
            disabled={disableControl ||
              (selectedPartFromSet.length === 0 && !selectedPart)}
            name="ADD" />
        </div>
        <Grid item xs={12} sm={5}>
          <CardContent xs={6}>
            <div style={{ 'font-weight': 'bold', fontSize: '14px', paddingLeft: '0px' }}>Selected Parts</div>
          </CardContent>

        </Grid>
        <Grid item xs={1} align="right">
          <CardContent xs={1}>
            <div style={{ 'font-weight': 'bold', fontSize: '14px' }}>Print Data</div>
          </CardContent>
        </Grid>
        <Grid item xs={1} align="right">
          <CardContent xs={3} style={{ marginTop: "-25px" }}>
            <Dropdown
              label={""}
              ctrllabel={("Select")}
              fnc={(e) => onChangeDropdown2(e)}
              value={printData}
              Isdisabled="true"
              options={customDropdownValues}
            />
          </CardContent>
        </Grid>

        <Grid id="parts2" item xs={12} sm={12}>
          <CardContent style={{ padding: '20px 0px' }}>
            <div style={{ padding: '1px' }}>
              <TableContainer component={Paper} style={{ border: '1px solid lightgrey' }}>
                <Table id="parts2" aria-label="simple table" className={classes.customThead}>
                  <TableHead className={classes.customThead}>
                    <TableRow >
                      <TableCell >Part No - Name</TableCell>
                      <TableCell align="center">Inventory</TableCell>
                      <TableCell align="left">Wasted</TableCell>
                      <TableCell align="left">DNR</TableCell>
                      <TableCell align="left">Revision</TableCell>
                      <TableCell align="left">Replenish to</TableCell>
                      <TableCell align="left">Ship to</TableCell>
                      <TableCell align="left">Lot Y/N</TableCell>
                      <TableCell align="left">Lot #</TableCell>
                      <TableCell align="left">Qty</TableCell>
                      <TableCell align="left">ARO Reference Number</TableCell>
                      <TableCell align="left">Shipping Method</TableCell>
                      <TableCell align="left">Shipping Priority</TableCell>
                      <TableCell align="left">DNS</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {usageParts && usageParts.map((row, index) => (
                    <TableRow id="parts" key={index} style={{ padding: "7px" }} className={classes.customThead}>
                      <TableCell size="small" component="th" scope="row">
                        {row.part_name}</TableCell>
                      <TableCell size="small" align="left"  >

                        <Dropdown label={""}
                          ctrllabel={"Select"}

                          disabled selected hidden
                          value={row.inventory__c}
                          fnc={(e) => onChangeDropdown(e, "inventory__c", row)}
                          Isdisabled={disableControl}
                          options={customReplenish} />
                      </TableCell>
                      <TableCell size="small" align="left" >

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={row.wasted}
                              onChange={(e) => onChangeCheckboxControl(e, "wasted", row)}
                              name="wasted"
                              value="wasted"
                              disabled={disableControl}
                              inputProps={{ 'aria-label': 'checkbox with default color' }} />
                          }
                        />
                      </TableCell>

                      <TableCell size="small" align="left">

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={!row.replenish__c}
                              onChange={(e) => onChangeCheckboxControl(e, "replenish__c", row)}
                              name="dnr"
                              value="dnr"
                              disabled={disableControl}
                              inputProps={{ 'aria-label': 'checkbox with default color' }} />
                          }
                        />
                      </TableCell>
                      <TableCell size="small" align="left">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={props.caseDetails.revision === true ? true : row.revisions__c}
                              onChange={(e) => onChangeCheckboxControl(e, "revisions__c", row)}
                              name="revisions__c"
                              value="revisions__c"
                              disabled={disableControl || disable}
                              inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell size="small" align="left">
                        <Dropdown label={""}
                          ctrllabel={!row.replenish__c ? "DNR" : "Select"}
                          value={row.ship_to__c}
                          fnc={(e) => onChangeDropdown(e, "ship_to__c", row)}
                          Isdisabled={disableControl || !row.replenish__c}
                          options={customReplenish2} />
                      </TableCell>
                      <TableCell size="small" align="left">
                        <Dropdown label={""}
                          ctrllabel={!row.replenish__c ? "DNR" : "Select"}
                          value={row.shipto_location__c}
                          fnc={(e) => onChangeDropdown(e, "shipto_location__c", row)}
                          Isdisabled={disableControl || !row.replenish__c}
                          options={customShipTo} />
                      </TableCell>
                      <TableCell size="small" align="left">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={row.lot_controlled__c}
                              onChange={(e) => onChangeCheckboxControl(e, "lot_controlled__c", row)}
                              name="lot"
                              value="lot"
                              disabled
                              inputProps={{ 'aria-label': 'checkbox with default color' }} />
                          }
                        />
                      </TableCell>
                      <TableCell size="small" align="left">
                        <FormControlLabel
                          control={
                            <TextField
                              onChange={(e) => onChangeLotNbr(e, row)}
                              inputProps={{ maxLength: 20 }}
                              name="lotNumber"
                              value={row.lot_number__c}
                              disabled={disableControl || !row.lot_controlled__c}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell size="small" align="left"><Dropdown label={""}
                        ctrllabel={"Select"}
                        fnc={(e) => onChangeDropdown(e, "quantity__c", row)}
                        value={row.lot_controlled__c ? 1 : (row.quantity__c === null ? row.cap_quantity : row.quantity__c)}
                        options={qtyOptions}
                        Isdisabled={disableControl || row.lot_controlled__c} /></TableCell>
                      <TableCell>

                        <InputTextField isdisabled={disableControl} value={(row.aro_reference_number__c == null || row.aro_reference_number__c == "!" ? WebOpsId : row.aro_reference_number__c)} fnc={(e) => onChangeDropdown(e, "aro_reference_number__c", row)} />
                      </TableCell>
                      <TableCell size="small" align="left"><Dropdown label={""}
                        ctrllabel={"Select"}
                        fnc={(e) => onChangeDropdown(e, "ship_method__c", row)}
                        value={row.ship_method__c}
                        options={shipMethodValues}
                        Isdisabled={disableControl} /></TableCell>
                      <TableCell size="small" align="left"><Dropdown label={""}
                        ctrllabel={"Select"}
                        fnc={(e) => onChangeDropdown(e, "ship_priority__c", row)}
                        value={row.ship_priority__c}
                        options={shipPriorityValues}
                        Isdisabled={disableControl} /></TableCell>
                      <TableCell size="small" align="left">

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={(row.dns__c == "true" || row.dns__c == true || row.dns__c == "True") ? true : false}
                              onChange={(e) => onChangeCheckboxControl(e, "dns__c", row)}
                              name="dns__c"
                              value="dns__c"
                              disabled={disableControl}
                              inputProps={{ 'aria-label': 'checkbox with default color' }} />
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div class="font-icon-wrapper" >
                          <DeleteIcon onClick={disableControl ? null : (e) => deleteParts(e, row)} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardContent>
            <InputTextField isdisabled={disableControl} label="Hospital Printed Name" value={hospitalPrintedName} fnc={onChangeHospitalName} />
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardContent>
            <SalesRep label={"Rep Printed Name"} isAstrikReq={true} Isdisabled={disableControl} onChangeSalesRep={onChangeSalesRep} options={salesRepsOptions} value={salesRep} />
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} justify='flex-end'>
          <CardContent>
            <div class="rFloat btnSave"><Buttons Icon="Save" toSave={true} disabled={disableControl} fnc={handleSaveUsage} name="SAVE" /></div>
          </CardContent>
        </Grid>
      </Grid>
    </div>
  );
}
